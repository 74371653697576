
import * as React from "react";
import { StoreProvider } from "./src/provider/StoreProvider";
import { fairyDustCursor } from './src/layouts/fairyDustCursor';
import { WishlistProvider } from "./src/provider/WishlistProvider";
import "./src/styles/typography.css";
import "./src/layouts/global.css";

export const wrapRootElement = ({ element }) => {
  fairyDustCursor();

  return (
    <StoreProvider><WishlistProvider>{element}</WishlistProvider></StoreProvider>
  )
};