import * as React from "react";

const defaultValues = {
    isOpen: false,
    wishlist: {
        lineItems: [],
    },
    addItemToWishlist: () => { },
    removeItemFromWishlist: () => { },
    setIsOpen: () => { },
};
  
export const WishlistContext = React.createContext(defaultValues);

const isBrowser = typeof window !== `undefined`;
const localStorageKey = `wishlist_id`;

export const WishlistProvider = ({ children }) => {
    const [isOpen, setIsOpen] = React.useState(false);
    const [lineItems, setLineItems] = React.useState(isBrowser && localStorage.getItem(localStorageKey) ? JSON.parse(localStorage.getItem(localStorageKey)) : []);

    const addItemToWishlist = (product) => {
        const newLineItems = [...lineItems, product];
        if (isBrowser) {
            localStorage.setItem(localStorageKey, JSON.stringify(newLineItems));
        }
        setLineItems(newLineItems);
    }

    const removeItemFromWishlist = (product) => {
        const newLineItems = [...lineItems.filter(item => item.handle !== product.handle)];
        if (isBrowser) {
            localStorage.setItem(localStorageKey, JSON.stringify(newLineItems));
        }
        setLineItems(newLineItems);
    }

    return (
        <WishlistContext.Provider
            value={{
                setIsOpen,
                isOpen,
                addItemToWishlist,
                removeItemFromWishlist,
                wishlist: {
                    lineItems
                }
            }}
        >
            {children}
        </WishlistContext.Provider>
    )
}